const localConfig = {
    AppID: 'afdce002-be5e-47f4-ba2f-b1cd7e3d4c22',
    AppTenantID: '75f01ec4-b658-4cc3-bdf9-7670ee9dfc7b',
    AppRedirectURL: 'http://localhost:5000',
    Halo_Ticket_URL : 'https://swissport.haloitsm.com',
    Genesys_Deployment_ID: '85849c59-1d40-437c-ad1a-bc6d5c6dbd9c',
    // Halo_Ticket_URL : 'https://swissport.haloitsm.com',
    // Genesys_Deployment_ID: '7e5a0e4c-24cf-423a-b128-b9fa0b248c63'

}

const developmentConfig = {
    AppID : 'afdce002-be5e-47f4-ba2f-b1cd7e3d4c22',
    AppTenantID : '75f01ec4-b658-4cc3-bdf9-7670ee9dfc7b',
    AppRedirectURL : 'https://itchatsupport-dev.swissport.com',
    Halo_Ticket_URL : 'https://swissportuat.haloitsm.com',
    Genesys_Deployment_ID : '85849c59-1d40-437c-ad1a-bc6d5c6dbd9c',
}

const uatConfig = {
    AppID: '727c4ec3-ae11-4992-a548-2f18434f48c4',
    AppTenantID: '75f01ec4-b658-4cc3-bdf9-7670ee9dfc7b',
    AppRedirectURL: 'https://itchatsupport-uat.swissport.com',
    Halo_Ticket_URL: 'https://swissportuat.haloitsm.com',
    Genesys_Deployment_ID: '85849c59-1d40-437c-ad1a-bc6d5c6dbd9c',
}

const prodConfig = {
    AppID: '0bce3389-b9b7-4240-8465-0f72e4a59f80',
    AppTenantID: '75f01ec4-b658-4cc3-bdf9-7670ee9dfc7b',
    AppRedirectURL: 'https://itchatsupport.swissport.com',
    Halo_Ticket_URL: 'https://swissport.haloitsm.com',
    Genesys_Deployment_ID: '7e5a0e4c-24cf-423a-b128-b9fa0b248c63',
}


const environment = 'production';

let config;

switch (environment) {
    case 'local':
        config = localConfig;
        break;
    case 'development':
        config = developmentConfig;
        break;
    case 'uat':
        config = uatConfig;
        break;
    case 'production':
        config = prodConfig;
        break;
}

export default config;